import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import type { NavigationEvent } from '@sveltejs/kit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';

const onError = init(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 1.0,
		tracesSampleRate: 1.0
	}
});
export const handleError = onError(
	(
		e: { error: unknown; event: NavigationEvent; status: number; message: string },
		sentryEventId?: string
	) => ({
		status: e.status || 500,
		message: e.message || 'Internal error: we are working on fixing this issue',
		sentryEventId: sentryEventId || ''
	})
);
